import { Student } from 'lmucs-shared-types'
import { getClassLevel } from 'utils/profiles'

interface ClassLevelDisplayProps {
  tutor: Student
}

export const ClassLevelDisplay = (props: ClassLevelDisplayProps) => {
  const { tutor } = props

  return <>{getClassLevel(tutor?.classOf)}</>
}
