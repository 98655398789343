import { DateTime } from 'luxon'

import { Box, Paper, Theme, Typography, useTheme } from '@mui/material'

import {
  ProfileSize,
  getNameStyles,
  getProfileContainerStyles,
  getProfileImageContainerStyle,
  getProfileIntervalStyles,
  getProfileNameStyle,
  getProfileStatusStyle,
} from 'styles/profile.styles'

import { ClassLevelDisplay } from 'components/ClassLevelDisplay'
import { ProfileIntervalDelimiter } from 'components/ProfileIntervalDelimiter'

import { ON_DUTY_COLOR } from 'constants/colors'
import { DISPLAY_SMALL, LUXON_HUMAN_TIME_FORMAT } from 'constants/formatting'

import { getShiftStatusColor, getTextShadowStringByStatus } from 'styles/shift.styles'

import { Event } from '@masaok/scanner-shared-next-public/types/event.types'
import { ShiftStatus } from 'types/supabase.database.custom.types'

import { extractCourseFromEventSummary } from 'utils/events'
import { getShiftStatus } from 'utils/shifts'

import { SessionProfile, Student } from 'lmucs-shared-types'

const useStyles = (theme: Theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-evenly',
    padding: theme.spacing(3),
    margin: theme.spacing(3),
  },

  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    ...getProfileContainerStyles(theme),
  },

  // Logo (required for fixed-ratio responsive images)
  imageContainer: {
    height: '40vh', // must be height number, or placeholder will be too wide
  },

  placeholder: {
    ...getProfileContainerStyles(theme),
    height: '100%', // must be height 100%
  },

  // Text stuff
  profileName: {
    ...getNameStyles(theme),
    fontSize: '3.0vw',
    lineHeight: '4.5vw',
  },

  classOf: {
    ...getNameStyles(theme),
    fontSize: '1.75vw',
    marginBottom: theme.spacing(1),
    color: theme.palette.grey[700],
  },

  course: {
    ...getNameStyles(theme),
    fontSize: '1.75vw',
    marginBottom: theme.spacing(1),
    color: theme.palette.grey[700],
  },

  timeframe: {
    ...getProfileIntervalStyles(theme),
    fontSize: '2.0vw',
  },

  description: {
    fontWeight: 'bold',
    fontSize: '1.0rem',
    marginTop: theme.spacing(2),
    color: theme.palette.grey[700],
  },

  status: {
    fontWeight: 'bold',
    fontSize: '3vw',
    textTransform: 'uppercase',
    color: ON_DUTY_COLOR,
  },
})

type ProfileCardProps = {
  event?: Event
  image?: string
  tutor?: Student
  profile?: SessionProfile
  size?: ProfileSize
}

export const ProfileCard = (props: ProfileCardProps) => {
  const classes = useStyles(useTheme())
  // const classes = useStyles(props)
  const { size, profile } = props

  console.log('PROFILE CARD > SIZE: ', size)
  // console.log('CARD PROFILE: ', profile)

  // const shiftStatus = profile?.shift.shift_status
  const shiftStatus = getShiftStatus(
    profile?.inputDateTimeIso as string,
    profile?.event as Event,
    profile?.shift?.shift_status as ShiftStatus
  )

  // console.log(`PROFILE SHIFT STATUS: -${shiftStatus}-`)

  return (
    <Paper sx={classes.paper} elevation={10}>
      <Box>
        <Box sx={{ ...classes.imageContainer }} style={getProfileImageContainerStyle(size)}>
          {profile?.imageUrl && (
            <img style={{ ...classes.placeholder }} src={profile.imageUrl} alt="logo" />
          )}
        </Box>
      </Box>
      <Typography sx={classes.profileName} style={getProfileNameStyle(size)}>
        {profile?.tutor?.nickname || profile?.tutor?.firstName}
      </Typography>

      {/* For quad tutor display, stack the descriptions instead of side by side */}
      {size === DISPLAY_SMALL ? (
        <>
          <Typography sx={classes.classOf}>
            <ClassLevelDisplay tutor={profile?.tutor as Student} />
          </Typography>
          <Typography sx={classes.course}>
            {extractCourseFromEventSummary(profile?.event.summary as string)}
          </Typography>
        </>
      ) : (
        <>
          <Typography sx={classes.classOf}>
            <ClassLevelDisplay tutor={profile?.tutor as Student} /> &middot;&nbsp;
            {extractCourseFromEventSummary(profile?.event.summary as string)}
          </Typography>
        </>
      )}

      <Typography sx={classes.timeframe}>
        {DateTime.fromISO(profile?.event.startISO as string).toFormat(LUXON_HUMAN_TIME_FORMAT)}
        <ProfileIntervalDelimiter size={size} />
        {DateTime.fromISO(profile?.event.endISO as string).toFormat(LUXON_HUMAN_TIME_FORMAT)}
      </Typography>
      <Typography
        sx={{
          ...getProfileStatusStyle(size),
          fontWeight: 'bold',
          color: getShiftStatusColor(shiftStatus, { profile: true }),
          textShadow: getTextShadowStringByStatus(shiftStatus, { profile: true }),
        }}
      >
        {shiftStatus}
      </Typography>
    </Paper>
  )
}
