import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { DateTime } from 'luxon'

import { makeStyles } from '@material-ui/core/styles'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { Tooltip, Typography } from '@material-ui/core'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { DISPLAY_MEDIUM, DISPLAY_SMALL, LOCAL_24HOUR_FORMAT } from 'constants/formatting'

import useRawFeeds from 'hooks/useRawFeeds'
import useEventsBetween from 'hooks/useEventsBetween'

import { Event } from '@masaok/scanner-shared-next-public/types/event.types'

import { EMOJIS } from 'constants/emojis'
import {
  filterEventsByTense,
  sliceEventsByTense,
  sortEventsByStartTimeAndSummary,
} from 'utils/events'
import { getRandomItem } from 'utils/random'
import { MAX_LISTED_EVENTS, MAX_EVENTS_LARGE_CELLS, MAX_PAST_EVENTS } from 'constants/listing'
import { getFontSize, isSpecialEvent } from 'utils/listing'
import { RAW_FEED_LIST } from 'constants/calendars'
import {
  CMSI_1010_BACKGROUND_COLOR,
  CMSI_2000_BACKGROUND_COLOR,
  CMSI_UPPER_DIVISION_BACKGROUND_COLOR,
} from 'constants/colors'

import * as ShiftController from 'controllers/shifts/ShiftController'
import { CANCELLED, ShiftRow, ShiftStatus } from 'types/supabase.database.custom.types'
import { useShiftsRealTimeUpdates } from 'controllers/shifts/useShifts'
import { getShiftStatusColor, getTextShadowStringByStatus } from 'styles/shift.styles'

import { getShiftStatus } from 'utils/shifts'
import { Box, CircularProgress } from '@mui/material'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      minHeight: '100%',
      minWidth: '90%',
    },

    loading: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(1),
    },

    header: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },

    content: {
      // backgroundColor: 'lightblue',
    },

    tablePaper: {
      margin: theme.spacing(3),
    },

    columnHeader: {
      fontSize: '2.1vw',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    },

    summary: {
      fontSize: '2.1vw',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },

    time: {
      display: 'flex',
      fontSize: '2.1vw',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
    },

    status: {
      fontSize: '80%',
      color: 'yellow',
    },

    noEvents: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '4.1vw',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      paddingTop: theme.spacing(40),
      color: 'white',
    },

    tooltip: {
      fontFamily: 'monospace',
      whiteSpace: 'pre',
    },

    error: {
      display: 'flex',
      flex: 1,
      color: 'red',
    },

    statusCell: {
      minWidth: '10rem',
    },

    headerTableCellRoot: {
      paddingRight: theme.spacing(0),
    },
  }),
  { name: 'ListSessions' }
)

interface ColumnHeader {
  field: string
  display: string
  flex: number
}

interface GetColumnHeadersParams {
  dateTimeISO: string
}

const getColumnHeaders = ({ dateTimeISO }: GetColumnHeadersParams): ColumnHeader[] => {
  const displayDate = DateTime.fromISO(dateTimeISO).toFormat(`cccc M/d h:mm a`)
  return [
    {
      field: 'summary',
      // display: `${displayDate} ${getRandomItem(EMOJIS)} Tutoring ${getRandomItem(EMOJIS)}`,
      display: `${displayDate} ${getRandomItem(EMOJIS)}`,
      flex: 3,
    },
    {
      field: 'start',
      display: 'Start',
      flex: 1,
    },
    {
      field: 'end',
      display: 'End',
      flex: 1,
    },
    {
      field: 'shift_status',
      display: 'Status',
      flex: 1.25,
    },
  ]
}

const renderTable = (classes: ClassNameMap, events: Event[], dateTimeISO: string) => {
  const fontSize = getFontSize(events.length)
  const now24 = DateTime.fromISO(dateTimeISO).toFormat(LOCAL_24HOUR_FORMAT)

  // console.log('EVENTS: ', events)

  return (
    <TableContainer component={Paper}>
      <Table size={events?.length > MAX_EVENTS_LARGE_CELLS ? DISPLAY_SMALL : DISPLAY_MEDIUM}>
        <TableHead>
          <TableRow
            style={{
              display: 'flex',
            }}
          >
            {getColumnHeaders({ dateTimeISO }).map(column => {
              const { flex } = column

              return (
                <TableCell
                  key={column.field}
                  style={{ flex }}
                  classes={
                    {
                      // root: classes.headerTableCellRoot,
                    }
                  }
                >
                  <Typography variant="h5" className={classes.columnHeader} style={{ fontSize }}>
                    {column.display}
                  </Typography>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {events?.map((event, index) => {
            let bgColor = 'white'

            console.info(`renderTable > event: `, event)
            // console.info('EVENT SUMMARY: ', event.summary)
            switch (true) {
              case isSpecialEvent(event):
                bgColor = 'gold'
                break
              case /1010/.test(event.summary):
                bgColor = CMSI_1010_BACKGROUND_COLOR
                break
              case /2\d+/.test(event.summary):
                bgColor = CMSI_2000_BACKGROUND_COLOR
                break
              default:
                bgColor = CMSI_UPPER_DIVISION_BACKGROUND_COLOR
            }

            const start24 = DateTime.fromISO(event.startISO).toFormat(LOCAL_24HOUR_FORMAT)
            const end24 = DateTime.fromISO(event.endISO).toFormat(LOCAL_24HOUR_FORMAT)

            const startDisplayString = DateTime.fromISO(event.startISO).toLocaleString(
              DateTime.TIME_SIMPLE
            )
            const endDisplayString = DateTime.fromISO(event.endISO).toLocaleString(
              DateTime.TIME_SIMPLE
            )

            const eventShiftStatus: ShiftStatus = getShiftStatus(
              dateTimeISO,
              event,
              event.shift_status as ShiftStatus
            )

            return (
              <TableRow
                key={index}
                style={{
                  display: 'flex',
                  backgroundColor: bgColor,
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    flex: 3,
                  }}
                >
                  <Typography
                    variant="body1"
                    className={classes.summary}
                    style={{
                      textDecoration: `${
                        now24 >= end24 || eventShiftStatus === CANCELLED ? 'line-through' : 'none'
                      }`,
                      textDecorationColor: 'rgba(0, 0, 0, 0.5)',
                      fontSize,
                    }}
                  >
                    {event.summary}
                  </Typography>
                </TableCell>
                <TableCell className={classes.timeCell} style={{ flex: 1 }}>
                  <Typography variant="body1" className={classes.time} style={{ fontSize }}>
                    {startDisplayString}
                  </Typography>
                </TableCell>
                <TableCell className={classes.timeCell} style={{ flex: 1 }}>
                  <Typography variant="body1" className={classes.time} style={{ fontSize }}>
                    {endDisplayString}
                  </Typography>
                </TableCell>
                <TableCell className={classes.statusCell} style={{ flex: 1.25 }}>
                  <Typography
                    variant="body1"
                    className={classes.time}
                    style={{
                      fontSize,
                      color: getShiftStatusColor(eventShiftStatus),
                      textShadow: getTextShadowStringByStatus(eventShiftStatus),
                    }}
                  >
                    {eventShiftStatus}
                  </Typography>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const ListSessions = (props: PageProps) => {
  const classes = useStyles(props)
  const { debugString } = props
  const { date, time } = useParams()

  const [loading, setLoading] = useState(true)
  const [eventsPrepared, setEventsPrepared] = useState(false)
  const [shiftsPrepared, setShiftsPrepared] = useState(false)

  const [inputDateTime, setInputDateTime] = useState<DateTime>(DateTime.local())
  const [inputDate, setInputDate] = useState<DateTime>(DateTime.local())
  const [inputStartOfDay, setInputStartOfDay] = useState<DateTime>(DateTime.local())
  const [inputEndOfDay, setInputEndOfDay] = useState<DateTime>(DateTime.local())

  const [icsData, setIcsData] = useState<string[]>([])

  // Events prepared for rendering
  const [events, setEvents] = useState<Event[]>([])

  // Shifts in Supabase (with shift_status info)
  const [shifts, setShifts] = useState<ShiftRow[]>([])

  // First, get raw feed data from Google Calendar
  // console.info('RAW_FEED_LIST: ', JSON.stringify(RAW_FEED_LIST, null, 2))
  const { contents, loading: contentsLoading, error: contentsError } = useRawFeeds(RAW_FEED_LIST)

  // Use ical-expander to narrow down events to those between start and end
  const { events: eventsBetween, loading: eventsLoading } = useEventsBetween(icsData, {
    from: inputStartOfDay.toISO() as string,
    to: inputEndOfDay.toISO() as string,
  })
  console.log('LIST > useEventsBetween > eventsBetween LENGTH: ', eventsBetween.length)

  const updatedRow = useShiftsRealTimeUpdates()

  // Set inputDate on page load, which fires useEventsBetween
  useEffect(() => {
    const inputTime = time ? time : '000000'

    const input =
      date && inputTime
        ? DateTime.fromFormat(`${date} ${inputTime}`, 'yyyyMMdd HHmmss')
        : DateTime.local()

    setInputDate(input)
    setInputDateTime(input)
    setInputStartOfDay(input.startOf('day'))
    setInputEndOfDay(input.endOf('day'))
  }, [date, time])

  // Save ICS Raw Data, which also fires useEventsBetween
  useEffect(() => {
    if (!contentsLoading) {
      const rawData = contents.map((content: any) => content.data)
      setIcsData(rawData)
    }
  }, [contentsLoading])

  // Fetch shifts from Supabase
  useEffect(() => {
    console.warn('EFFECT > FETCHING SHIFTS FROM SUPABASE ...')

    const prepareShiftsAndEvents = async () => {
      // Fetch shifts from Supabase
      const todayShifts: ShiftRow[] = await ShiftController.fetchShiftsBetween(
        inputStartOfDay.toISO() as string,
        inputEndOfDay.toISO() as string
      )

      console.warn('SHIFTS PREPARED > TODAY SHIFTS: ', todayShifts)

      setShifts(todayShifts)
      setShiftsPrepared(true)

      // console.warn('EFFECT > PREPARING EVENTS ...')

      // Sort Google Calendar Events by start time
      console.info('LIST > useEffect > prepareShiftsAndEvents > BEFORE SORTING: ', eventsBetween)
      const sortedEvents: Event[] = sortEventsByStartTimeAndSummary(eventsBetween)
      console.info('LIST > sortedEvents: ', sortedEvents)

      const filteredEvents = filterEventsByTense(sortedEvents, inputDateTime.toISO() || '')
      // console.info('LIST > filteredEvents: ', filteredEvents)

      const slicedEvents = sliceEventsByTense(
        filteredEvents.pastEvents,
        filteredEvents.currentEvents,
        filteredEvents.futureEvents,
        MAX_PAST_EVENTS,
        MAX_LISTED_EVENTS
      )

      // console.log('LIST > slicedEvents LENGTH: ', slicedEvents.length)

      // Map existing events with shifts matching on event.startISO === shift.start_time_iso and event.summary === shift.summary
      const eventsWithShifts: Event[] = slicedEvents.map(event => {
        const shift = todayShifts.find(
          shift => shift.start_time_iso === event.startISO && shift.summary === event.summary
        )

        // console.log('MATCH EVENT: ', event)
        // console.log('MATCH SHIFT: ', shift)

        return {
          ...event,
          shift_id: shift?.shift_id,
          shift_status: shift?.shift_status,
        }
      })

      setEvents(eventsWithShifts)
      setEventsPrepared(true)
      setLoading(false)
    }

    // if (eventsBetween.length > 0) prepareShiftsAndEvents()
    prepareShiftsAndEvents()
  }, [eventsBetween, updatedRow])

  // console.info(
  //   JSON.stringify(
  //     {
  //       loading,
  //       inputStartOfDay,
  //       inputEndOfDay,
  //       icsDataLength: icsData.length,
  //       contentsLoading,
  //       eventsLoading,
  //       eventsPrepared,
  //       shiftsPrepared,
  //       eventsLength: events.length,
  //     },
  //     null,
  //     2
  //   )
  // )

  console.info('LIST > events BEFORE RENDERING: ', events)

  return (
    <div className={classes.root}>
      <Helmet>
        <title>List Sessions</title>
      </Helmet>
      <div className={classes.content}>
        <Tooltip
          title={debugString || ''}
          classes={{
            tooltip: classes.tooltip,
          }}
        >
          <>
            {loading ? (
              <Box className={classes.loading}>
                <CircularProgress />
              </Box>
            ) : events.length > 0 ? (
              <Paper className={classes.tablePaper}>
                {renderTable(classes, events, inputDate.toISO() || '')}
              </Paper>
            ) : (
              <div className={classes.noEvents}>
                <div>No Tutoring Sessions Today</div>
                {contentsError && <div className={classes.error}>{contentsError}</div>}
              </div>
            )}
          </>
        </Tooltip>
      </div>
    </div>
  )
}
