import React, { useState } from 'react'
import { Theme, ThemeProvider } from '@material-ui/core/styles'

import { getModeFromPath } from 'utils/paths'

// General themes
import defaultTheme from './default'
import mobileTheme from './mobile'

// Mode-specific themes
import student from './modes/student'
import tutor from './modes/tutor'
import display from './modes/display'

const themeMap: {
  [key: string]: Theme
} = {
  defaultTheme,
  mobileTheme,
  student,
  tutor,
  display,
  test: display,
  sandbox: display,
}

export const ThemeContext = React.createContext((themeName: string) => {})

const getDefaultTheme = () => {
  const mode = getModeFromPath()
  // if (!(mode in themeMap)) console.warn(`Unknown theme mode: "${mode}" -- using default instead...`)
  return mode in themeMap ? themeMap[mode] : display
}

export interface CustomThemeProviderProps {
  children: React.ReactNode
}

const CustomThemeProvider = (props: CustomThemeProviderProps) => {
  const { children } = props
  // State to hold the selected theme
  const [theme, setTheme] = useState(getDefaultTheme())

  const setThemeName = (themeName: string) => {
    localStorage.setItem('appTheme', themeName)
    setTheme(themeMap[themeName])
  }

  // console.info(`CUSTOM THEME PROVIDER > theme: `, theme)

  return (
    <ThemeContext.Provider value={setThemeName}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  )
}

export default CustomThemeProvider
