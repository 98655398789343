import { makeStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'

import { DateTime } from 'luxon'

import { getRandomInt } from 'utils/math'
import { Helmet } from 'react-helmet-async'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      minHeight: '100%',
      minWidth: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },

    datetime: {
      marginTop: theme.spacing(3),
      fontWeight: 'bold',
      fontSize: '5vw',
      textTransform: 'uppercase',
      color: 'white', // Movie theme
    },

    welcome: {
      fontWeight: 'bold',
      fontSize: '5vw',
      textTransform: 'uppercase',
      color: 'white', // Movie theme
    },

    lab: {
      fontWeight: 'bold',
      fontSize: '6vw',
      textTransform: 'uppercase',
      color: 'white', // Movie theme
    },

    message: {
      fontWeight: 'bold',
      fontSize: '6vw',
      textTransform: 'uppercase',
      color: 'white',
    },

    loading: {
      width: '50%',
      marginTop: theme.spacing(10),
    },

    error: {
      fontWeight: 'bold',
      fontSize: '2vw',
      textTransform: 'uppercase',
      color: 'red',
    },
  }),
  { name: 'Welcome' }
)

const Welcome = (props: any) => {
  const classes = useStyles(props)

  const { error } = props

  const localtime = DateTime.local()

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Welcome</title>
      </Helmet>
      <div
        className={classes.datetime}
        style={{
          marginBottom: getRandomInt(0, 50),
          fontSize: `${getRandomInt(3, 4)}vw`,
        }}
      >
        {localtime.toFormat('ccc LLL d h:mm a')}
      </div>
      <div
        className={classes.welcome}
        style={{
          marginBottom: getRandomInt(0, 50),
          fontSize: `${getRandomInt(3, 5)}vw`,
        }}
      >
        Welcome to the
      </div>
      <div
        className={classes.lab}
        style={{
          fontSize: `${getRandomInt(5, 7)}vw`,
          marginBottom: getRandomInt(0, 50),
        }}
      >
        Computer Science Lab
      </div>
      <div
        className={classes.message}
        style={{
          fontSize: `${getRandomInt(2, 4)}vw`,
        }}
      >
        (no tutoring sessions currently)
      </div>
      {error && <div className={classes.error}>{error}</div>}
      <div className={classes.loading}>
        <LinearProgress />
      </div>
    </div>
  )
}

export default Welcome
