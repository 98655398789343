import { useEffect, useState } from 'react'

import { DateTime } from 'luxon'

import { Box, CircularProgress, Paper, Theme, Typography, useTheme } from '@mui/material'

import { ON_DUTY_COLOR } from 'constants/colors'
import { LUXON_HUMAN_TIME_FORMAT } from 'constants/formatting'

import { ClassLevelDisplay } from 'components/ClassLevelDisplay'

import { getProfileContainerStyles, getProfileStatusStyle } from 'styles/profile.styles'

import { TutorDisplayProps } from 'types/displayTypes'
import { Event } from '@masaok/scanner-shared-next-public/types/event.types'
import { SessionProfile, Student } from 'lmucs-shared-types'
import { DEFAULT_SHIFT_STATUS, ShiftRow, ShiftStatus } from 'types/supabase.database.custom.types'

import { getShiftStatus } from 'utils/shifts'
import { getShiftStatusColor, getTextShadowStringByStatus } from 'styles/shift.styles'

const useStyles = (theme: Theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(3),
    margin: theme.spacing(3),
    ...getProfileContainerStyles(theme),
  },

  loading: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
  },

  leftPanel: {
    display: 'flex',
    flex: 1,
    padding: theme.spacing(3),
    justifyContent: 'center',
  },

  // Container required for fixed-ratio responsive images
  imageContainer: {
    height: '70vh', // must be height number, or placeholder will be too wide
  },

  placeholder: {
    // ...getProfileContainerStyles(theme),
    height: '100%', // must be height 100%
  },

  // Right Panel
  rightPanel: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
  },

  // Text stuff
  hello: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '3vw',
    color: theme.palette.grey[700],
    marginBottom: theme.spacing(1),
  },

  name: {
    fontWeight: 'bold',
    fontSize: '6vw',
    textTransform: 'uppercase',
    lineHeight: '6vw',
  },

  classOf: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '3vw',
    marginTop: theme.spacing(2),
    color: theme.palette.grey[700],
  },

  tutoringFrom: {
    fontWeight: 'bold',
    fontSize: '3vw',
    color: theme.palette.grey[700],
    marginTop: theme.spacing(3),
  },

  timeframe: {
    fontWeight: 'bold',
    fontSize: '3vw',
  },

  description: {
    fontWeight: 'bold',
    fontSize: '1.0rem',
    marginTop: theme.spacing(2),
    color: theme.palette.grey[700],
  },

  status: {
    fontWeight: 'bold',
    fontSize: '5vw',
    textTransform: 'uppercase',
    color: ON_DUTY_COLOR,
    marginTop: theme.spacing(2),
  },
})

const SingleTutor = (props: TutorDisplayProps) => {
  // const classes = useStyles(props)
  const classes = useStyles(useTheme())
  const { profiles } = props

  const [tutor, setTutor] = useState<Student>({} as Student)
  const [event, setEvent] = useState<Event>({} as Event)
  const [image, setImage] = useState<string>('')
  const [loading, setLoading] = useState(true)

  const [profile, setProfile] = useState<SessionProfile>()
  const [shiftStatus, setShiftStatus] = useState<ShiftStatus>(DEFAULT_SHIFT_STATUS)

  useEffect(() => {
    if (profiles && profiles.length > 0) {
      const profile = profiles?.[0] as SessionProfile
      console.log('SINGLE > PROFILE: ', profile)

      const newTutor: Student = profile?.tutor as Student
      const newEvent: Event = profile?.event as Event
      const newImage: string = profile?.imageUrl as string

      // console.log('SINGLE > NEW TUTOR: ', newTutor)

      // const shiftStatus = profile?.shift.shift_status
      const shiftStatus = getShiftStatus(
        profile?.inputDateTimeIso as string,
        newEvent,
        profile?.shift?.shift_status as ShiftStatus
      )

      setShiftStatus(shiftStatus)
      setProfile(profile)
      setTutor(newTutor)
      setEvent(newEvent)
      setImage(newImage)
      setLoading(false)
    }
  }, [profiles])

  // console.log('SINGLE TUTOR: ', tutor)

  return loading ? (
    <Box sx={classes.loading}>
      <CircularProgress />
    </Box>
  ) : (
    <Paper sx={classes.root} elevation={10}>
      <Box sx={classes.leftPanel}>
        <Box sx={classes.imageContainer}>
          <img style={{ height: '100%' }} src={image} alt="logo" />
        </Box>
      </Box>
      <Box sx={classes.rightPanel}>
        <Typography sx={classes.hello}>HELLO, MY NAME IS</Typography>
        <Typography sx={classes.name}>{tutor?.firstName}</Typography>
        <Typography sx={classes.name}>{tutor?.lastName}</Typography>
        <Typography sx={classes.classOf}>
          <ClassLevelDisplay tutor={tutor} />
        </Typography>
        <Typography sx={classes.tutoringFrom}>I'm tutoring from</Typography>
        <Typography sx={classes.timeframe}>
          {DateTime.fromISO(event?.startISO).toFormat(LUXON_HUMAN_TIME_FORMAT)}&nbsp;to&nbsp;
          {DateTime.fromISO(event?.endISO).toFormat(LUXON_HUMAN_TIME_FORMAT)}
        </Typography>
        <Typography
          sx={{
            ...getProfileStatusStyle(),
            fontWeight: 'bold',
            fontSize: '4.0vw',
            color: getShiftStatusColor(shiftStatus, { profile: true }),
            textShadow: getTextShadowStringByStatus(shiftStatus, { profile: true }),
          }}
        >
          {shiftStatus}
        </Typography>
      </Box>
    </Paper>
  )
}

export default SingleTutor
