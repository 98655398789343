import { makeStyles } from '@material-ui/core/styles'
import { TutorDisplayProps } from 'types/displayTypes'
import { ProfileCard } from './ProfileCard'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-evenly',
      padding: theme.spacing(3),
      margin: theme.spacing(3),
    },
  }),
  { name: 'TripleTutor' }
)

const TripleTutor = (props: TutorDisplayProps) => {
  const classes = useStyles(props)
  const { profiles } = props

  // console.log('TRIPLE > PROFILES: ', JSON.stringify(profiles, null, 2))

  return (
    <div className={classes.root}>
      {profiles?.map((profile, index) => {
        return <ProfileCard key={index} profile={profile} />
      })}
    </div>
  )
}

export default TripleTutor
