import { LOCAL_24HOUR_FORMAT } from 'constants/formatting'
import { DateTime } from 'luxon'
import { Event } from '@masaok/scanner-shared-next-public/types/event.types'

export const extractCourseFromEventSummary = (input: string): string | undefined => {
  console.log('EXTRACT COURSE FROM SUMMARY: ', input)
  const match = input?.match(/\((.*?)\)/)
  const result = match ? match[1] : undefined
  console.log('EXTRACT COURSE FROM SUMMARY RESULT: ', result)
  return result
}

export const sortEventsByStartTimeAndSummary = (events: Event[]) => {
  const mappedEvents: Event[] = events.map(event => {
    return {
      ...event,
      startTimeISO: DateTime.fromISO(event.startISO).toISOTime() || '',
      endTimeISO: DateTime.fromISO(event.endISO).toISOTime() || '',
    }
  })

  // Sort to sort by start time, then end time, then summary
  const sortedEvents: Event[] = mappedEvents.sort((a, b) => {
    if (a.startTimeISO > b.startTimeISO) {
      return 1
    } else if (a.startTimeISO < b.startTimeISO) {
      return -1
    }

    // If start times are equal, compare end times
    if (a.endTimeISO > b.endTimeISO) {
      return 1
    } else if (a.endTimeISO < b.endTimeISO) {
      return -1
    }

    // If both start and end times are equal, compare summary
    if (a.summary > b.summary) {
      return 1
    } else if (a.summary < b.summary) {
      return -1
    }

    return 0 // If everything is equal
  })

  return sortedEvents
}

export const filterEventsByTense = (events: Event[], inputDateTimeISO: string) => {
  const now24 = inputDateTimeISO

  const pastEvents: Event[] = []
  const currentEvents: Event[] = []
  const futureEvents: Event[] = []

  events.forEach(event => {
    const eventStart24 = event.startISO
    const eventEnd24 = event.endISO

    // console.info({
    //   summary: event.summary,
    //   eventStart24,
    //   eventEnd24,
    //   now24,
    //   inputDateTimeISO,
    //   startISO: event.startISO,
    //   endISO: event.endISO,
    // })

    if (eventEnd24 < now24) {
      pastEvents.push(event)
    } else if (now24 <= eventStart24) {
      futureEvents.push(event)
    } else {
      currentEvents.push(event)
    }
  })

  return { pastEvents, currentEvents, futureEvents }
}

export const sliceEventsByTense = (
  pastEvents: Event[],
  currentEvents: Event[],
  futureEvents: Event[],
  maxPastEvents: number,
  maxEvents: number
): Event[] => {
  // Determine the number of past events to show first
  let numPastEvents = maxPastEvents

  // If we need more past events, then modify that number
  if (maxEvents - (currentEvents.length + futureEvents.length) > maxPastEvents) {
    numPastEvents = maxEvents - (currentEvents.length + futureEvents.length)
  }

  // Slice past and future events as needed
  const slicedPastEvents = pastEvents.slice(numPastEvents * -1) // slice from the end of the array
  const slicedFutureEvents = futureEvents.slice(
    0,
    maxEvents - slicedPastEvents.length - currentEvents.length
  )

  return [...slicedPastEvents, ...currentEvents, ...slicedFutureEvents]
}
