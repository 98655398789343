export const MAX_PAST_EVENTS = 3
export const MAX_LISTED_EVENTS = 10

export const MAX_EVENTS_LARGE_CELLS = 8
export const MAX_EVENTS_LARGE_TEXT = 7
export const MAX_EVENTS_NORMAL_TEXT = 10

export const LARGE_FONT_SIZE = 'clamp(1rem, 2.5vw, 3rem)'
export const NORMAL_FONT_SIZE = 'clamp(1rem, 2.2vw, 3rem)'
export const SMALL_FONT_SIZE = 'clamp(1rem, 1.6vw, 2rem)'
