import { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Routes, Route, Navigate, BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import { CssBaseline } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import theme from 'themes/default'
import ThemeProvider from 'themes/CustomThemeProvider'

// Desktop
import Homepage from 'desktop/modes/display/homepage/Homepage'
import Dashboard from 'desktop/modes/display/dashboard/Dashboard'

// Desktop Sandboxes
import PageBlue from 'desktop/modes/display/pages/PageBlue'
import PageRed from 'desktop/modes/display/pages/PageRed'
import PageGreen from 'desktop/modes/display/pages/PageGreen'
import OnDuty from 'desktop/modes/display/pages/OnDuty'
import Welcome from 'desktop/modes/display/pages/Welcome'

import { HelloWorld } from 'pages/HelloWorld'
import { ListSessions } from 'desktop/modes/display/pages/ListSessions'
import { PhotoRoster } from 'pages/PhotoRoster'
import { DesktopGridContainerMovie } from 'containers/DesktopGridContainerMovie'

import DataTable from 'examples/DataTable'
import StylingRowsGrid from 'examples/StylingRowsGrid'
import { Environment } from 'pages/Environment'
import { DebugContainer } from 'containers/DebugContainer'

// makeStyles (new) vs createStyles (old)
// https://smartdevpreneur.com/material-ui-makestyles-usestyles-createstyles-and-withstyles-explained/
const useStyles = makeStyles(
  () => ({
    root: {
      display: 'grid',
      height: '100vh',
      width: '100vw',
      // backgroundColor: 'lightblue', // this works
    },
  }),
  { name: 'App' }
)

const queryClient = new QueryClient()

const App = () => {
  const classes = useStyles()

  // Breakpoints
  // https://v4.mui.com/customization/breakpoints/
  // https://v4.mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

  const [, setLoading] = useState(true)

  useEffect(() => {
    if (isSmUp) setLoading(false)
  }, [isSmUp])

  return (
    <ThemeProvider>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <main className={classes.root}>
            <CssBaseline />
            <Helmet>
              <title>App.jsx</title>
            </Helmet>
            <CssBaseline />
            <Router>
              <Routes>
                <Route element={<DebugContainer />}>
                  <Route path="/" element={<Navigate to="/display" />} />
                </Route>
                <Route path="/">
                  <Route path="env" element={<Environment />} />
                  <Route path="roster" element={<PhotoRoster />} />
                  <Route path="examples">
                    <Route path="data" element={<DataTable />} />
                    <Route path="styling" element={<StylingRowsGrid />} />
                  </Route>
                </Route>
                <Route path="/" element={<DesktopGridContainerMovie />}>
                  <Route path="hello" element={<HelloWorld />} />
                  <Route path="display" element={<Homepage />} />
                  <Route path="debug" element={<Homepage />} />

                  <Route path="onduty" element={<OnDuty />}>
                    <Route path=":date" element={<OnDuty />} />
                    <Route path=":date/:time" element={<OnDuty />} />
                  </Route>

                  <Route path="list" element={<ListSessions />}>
                    <Route path=":date" element={<ListSessions />} />
                    <Route path=":date/:time" element={<ListSessions />} />
                  </Route>

                  <Route path="welcome" element={<Welcome />} />
                  <Route path="blue" element={<PageBlue />} />
                  <Route path="red" element={<PageRed />} />
                  <Route path="green" element={<PageGreen />} />
                  <Route path="dashboard" element={<Dashboard />} />
                </Route>
              </Routes>
            </Router>
          </main>
        </QueryClientProvider>
      </HelmetProvider>
    </ThemeProvider>
  )
}

export default App
