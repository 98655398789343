import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { TutorDisplayProps } from 'types/displayTypes'
import { getStudentPhotoPath } from 'utils/profiles'
import { ProfileCard } from './ProfileCard'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-evenly',
      padding: theme.spacing(3),
      margin: theme.spacing(3),
    },
  }),
  { name: 'DoubleTutor' }
)

const DoubleTutor = (props: TutorDisplayProps) => {
  const classes = useStyles(props)
  const { profiles } = props

  return (
    <div className={classes.root}>
      {profiles?.map((profile, index) => {
        return <ProfileCard key={index} profile={profile} />
      })}
    </div>
  )
}

export default DoubleTutor
