import axios from 'axios'

import { supabase } from 'clients/supabaseClient'
import { ShiftRow } from 'types/supabase.database.custom.types'

export const fetchShiftByEmailAndStartTime = async (
  email: string,
  startTimeUtcIso: string
): Promise<ShiftRow[]> => {
  try {
    // console.log('fetchShiftByEmailAndStartTime: ', email, startTimeUtcIso)

    const url = `${process.env.REACT_APP_SCANNER_API}/shifts/find/${email}/${startTimeUtcIso}`
    // console.log('url: ', url)
    const response = await axios.get(url)

    // console.log('RESPONSE DATA: ', response.data)
    return response.data
  } catch (error) {
    console.trace(error)
    throw new Error('fetchShiftByEmailAndStartTime failed.')
  }
}

export const fetchShiftsByEmail = async (email: string): Promise<ShiftRow[]> => {
  try {
    const { data, error } = await supabase
      .from('shifts')
      .select()
      .eq('email', email)
      .order('start_time', { ascending: true })

    if (error) throw error

    return data
  } catch (error) {
    throw new Error(`Network response was not ok: ${error}`)
  }
}

// Function to fetch shifts within the current day using Luxon
export const fetchShiftsBetween = async (
  startTimeIso: string,
  endTimeIso: string
): Promise<ShiftRow[]> => {
  const { data, error } = await supabase
    .from('shifts')
    .select('*')
    .gte('start_time', startTimeIso)
    .lte('start_time', endTimeIso)

  if (error) {
    console.error('Error fetching shifts', error)
    return []
  }

  return data
}
