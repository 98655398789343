/**
 * Given a start date, end date, and a list of raw ICS data, fetch all events between those dates
 */
import { useEffect, useState } from 'react'
import IcalExpander from 'ical-expander'

import { DateTime } from 'luxon'
import { Event } from '@masaok/scanner-shared-next-public/types/event.types'

export type UseEventsBetweenOptions = {
  from?: string
  to?: string
}

const useEventsBetween = (data: string[], options: UseEventsBetweenOptions = {}) => {
  const { from, to } = options

  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<unknown>()
  const [events, setEvents] = useState<Event[]>([])

  // Get all events between the start and end dates
  useEffect(() => {
    const allEvents: Event[][] = []

    try {
      if (!data) throw new Error('data empty')
      for (const ics of data) {
        const icalExpander = new IcalExpander({ ics, maxIterations: 500 })
        const events = icalExpander.between(
          new Date(from ? from : DateTime.utc().toISO() || ''),

          // If no end date is provided, default to one hour from now
          new Date(to ? to : DateTime.utc().plus({ hours: 1 }).toISO() || '')
        )

        // Events vs. Occurrences: occurrences are events that occur on a recurring basis
        // https://stackoverflow.com/a/34692915/10415969
        const mappedEvents = events.events.map((e: any) => ({
          startISO: DateTime.fromJSDate(e.startDate.toJSDate()).toISO(),
          endISO: DateTime.fromJSDate(e.endDate.toJSDate()).toISO(),
          summary: e.summary,
        }))

        const mappedOccurrences = events.occurrences.map((o: any) => ({
          startISO: DateTime.fromJSDate(o.startDate.toJSDate()).toISO(),
          endISO: DateTime.fromJSDate(o.endDate.toJSDate()).toISO(),
          summary: o.item.summary,
        }))

        allEvents.push([].concat(mappedEvents, mappedOccurrences))
      }

      const flattened = allEvents.flat()

      // console.warn('FLATTENED EVENTS LENGTH: ', flattened.length)

      setEvents(flattened)
      setLoading(false)
    } catch (err: unknown) {
      console.warn(err)
      setError(err)
    }
  }, [data, from, to])

  return { events, loading, error }
}

export default useEventsBetween
