import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { DateTime } from 'luxon'

import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Box } from '@mui/material'

import SingleTutor from '../tutors/SingleTutor'
import DoubleTutor from '../tutors/DoubleTutor'
import TripleTutor from '../tutors/TripleTutor'

import Welcome from './Welcome'

import useRawFeeds from 'hooks/useRawFeeds'
import useEventsBetween from 'hooks/useEventsBetween'

import { Event } from '@masaok/scanner-shared-next-public/types/event.types'
import { QuadTutor } from '../tutors/QuadTutor'
import { RAW_FEED_LIST } from 'constants/calendars'
import { extractNameParts } from 'utils/events.names'
import { useShiftsRealTimeUpdates } from 'controllers/shifts/useShifts'
import {
  getDefaultPlaceholderPhotoPath,
  getPlaceholderPhotoPath,
  getStudentPhotoPath,
} from 'utils/profiles'

import * as ShiftController from 'controllers/shifts/ShiftController'
import * as UserController from 'controllers/users/UserController'

import { useSignUps } from 'hooks/useSignUps'

import { jsonDump } from 'utils/debugging'
import { checkUrl } from 'utils/urls'

import { SessionProfile, Student } from 'lmucs-shared-types'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      minHeight: '100%',
      minWidth: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      paddingBottom: theme.spacing(2), // pushes main content up slightly (fits better on TV display)
    },

    loading: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(1),
    },

    header: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },

    content: {
      // backgroundColor: 'lightblue',
    },

    tableContainer: {
      display: 'flex',
      // flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    tablePaper: {
      margin: theme.spacing(3),
    },

    // Tutor Display
    tutorContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: '100vw',
    },

    tutorDisplay: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      minWidth: '100vw',
    },

    tooltip: {
      fontFamily: 'monospace',
      whiteSpace: 'pre',
    },
  }),
  { name: 'OnDuty' }
)

const OnDuty = (props: PageProps) => {
  const classes = useStyles(props)

  const { date, time } = useParams()

  const [inputDateTime, setInputDateTime] = useState<DateTime>(DateTime.local())

  const [loading, setLoading] = useState(true)

  const [dateTimeDisplay, setDateTimeDisplay] = useState('')

  const [tutors, setTutors] = useState<Student[]>([])

  const { data: signups } = useSignUps()

  console.log('SIGNUPS OK: ', signups)

  const [icsData, setIcsData] = useState<string[]>([])
  const [icsEvents, setIcsEvents] = useState<Event[]>([])

  const [currentEvents, setCurrentEvents] = useState<Event[]>([]) // events happening now

  const [profiles, setProfiles] = useState<SessionProfile[]>([])
  const [profilesPrepared, setProfilesPrepared] = useState(false)

  // First, get raw feed data from Google Calendar
  const { contents, loading: contentsLoading, error: contentsError } = useRawFeeds(RAW_FEED_LIST)
  // console.info('ONDUTY > useRawFeeds > contents: ', contents)

  // Use ical-expander to narrow down events to those between start and end
  const { events: eventsBetween, loading: eventsLoading } = useEventsBetween(icsData, {
    from: inputDateTime.toISO() || '',
    to: inputDateTime.toISO() || '',
  })

  console.info('ON DUTY > useEventsBetween > eventsBetween: ', eventsBetween)

  const updatedRow = useShiftsRealTimeUpdates()

  // Set inputDate on page load
  useEffect(() => {
    const inputTime = time ? time : '000000'

    const input =
      date && inputTime
        ? DateTime.fromFormat(`${date} ${inputTime}`, 'yyyyMMdd HHmmss')
        : DateTime.local()

    setInputDateTime(input)
  }, [date, time])

  // Set display time
  useEffect(() => {
    setDateTimeDisplay(inputDateTime.toLocaleString(DateTime.DATETIME_HUGE_WITH_SECONDS))
  }, [inputDateTime])

  // Save ICS Raw Data
  useEffect(() => {
    if (!contentsLoading) {
      const rawData = contents.map((content: any) => content.data)
      setIcsData(rawData)
    }
  }, [contentsLoading])

  // Using raw eventsBetween, filter for events happening now
  useEffect(() => {
    setIcsEvents(eventsBetween)

    // Filter the ICS events to find events happening now
    const currentEvents = eventsBetween.filter(event => {
      const start = DateTime.fromISO(event.startISO).toLocal().toISOTime() || ''
      const end = DateTime.fromISO(event.endISO).toLocal().toISOTime() || ''

      const now =
        date && time
          ? DateTime.fromFormat(`${date} ${time}`, 'yyyyMMdd HHmmss').toLocal().toISOTime() || ''
          : DateTime.local().toISOTime() || ''

      return start <= now && now < end
    })

    console.info('EFFECT > CURRENT EVENTS: ', currentEvents)
    setCurrentEvents(currentEvents)

    // Process the currentEvents to create the tutors array based on student API data
    const tutors: Student[] = []
    for (const event of currentEvents) {
      const { firstName, lastInitial } = extractNameParts(event.summary) || {
        firstName: '',
        lastInitial: '',
      }

      const regex1 = new RegExp(firstName, 'i')
      const regex2 = new RegExp(`^${lastInitial}`, 'i')

      console.info('EFFECT > setTutors:', { regex1, regex2 })

      const results = signups?.filter(row => {
        return (
          (regex1.test(row.firstName as string) || regex1.test(row.nickname as string)) &&
          regex2.test(row.lastName as string)
        )
      })
      console.info('EFFECT > setTutors:', results)

      if (results && results.length > 0) {
        const result = results[0]

        const student: Student = {
          firstName: result.firstName as string,
          lastName: result.lastName as string,
          nickname: result.nickname as string,
          classOf: result.classOf as number,
        }

        tutors.push(student)
      }
    }
    console.info('TUTORS CREATED: ', tutors)
    setTutors(tutors)
  }, [eventsBetween])

  // Connect tutors and events in profiles to avoid incorrect tutor/event data
  useEffect(() => {
    const createProfiles = async () => {
      const profiles: SessionProfile[] = []

      for (let i = 0; i < tutors.length; i++) {
        const event: Event = currentEvents[i]
        const tutor = tutors[i]

        console.info('CREATING PROFILE > tutor: ', tutor)

        // Parse the event summary to get the student first name and last initial
        const { firstName, lastInitial } = extractNameParts(event.summary) || {
          firstName: '',
          lastInitial: '',
        }

        let imageUrl = await getStudentPhotoPath(tutor)
        const isImageUrlValid = await checkUrl(imageUrl)
        console.info('IS IMAGE URL VALID: ', isImageUrlValid)

        if (!isImageUrlValid) imageUrl = getDefaultPlaceholderPhotoPath()

        // Use axios to fetch the user row
        const userRow = await UserController.fetchUserByFirstLast(firstName, lastInitial)

        if (userRow.length === 0) {
          console.warn('USER NOT FOUND: ', firstName, lastInitial)
          continue
        } else {
          console.warn('USER FOUND: ', firstName, lastInitial, userRow)
        }

        const email = userRow[0].email as string

        const shiftStartTimeUtcIso = DateTime.fromISO(event.startISO).toUTC().toISO() as string

        const shiftRow = await ShiftController.fetchShiftByEmailAndStartTime(
          email,
          shiftStartTimeUtcIso
        )

        console.info('SHIFT ROW: ', shiftRow)

        const profile: SessionProfile = {
          tutor,
          event,
          imageUrl,
          user: userRow[0],
          shift: shiftRow[0],
          inputDateTimeIso: inputDateTime.toUTC().toISO() as string,
        }

        // console.log('NEW PROFILE: ', profile)

        profiles.push(profile)
      }

      console.info('EFFECT > PROFILES CREATED')
      setProfiles(profiles)
      setProfilesPrepared(true)
    }

    createProfiles()
  }, [tutors])

  // Handle shift row updates
  useEffect(() => {
    console.info('ON DUTY > UPDATED ROW: ', updatedRow)

    // Find the profile with shift with matching email and start_time
    const matchIndex = profiles.findIndex(profile => {
      return (
        profile.shift?.email === updatedRow?.email &&
        profile.shift?.start_time === updatedRow?.start_time
      )
    })

    const newProfiles = [...profiles] // shallow copy!!! (in case React doesn't detect the change)

    if (newProfiles[matchIndex]) {
      newProfiles[matchIndex].shift.shift_status = updatedRow?.shift_status
      setProfiles(newProfiles)
    }
  }, [updatedRow])

  useEffect(() => {
    // if (profiles.length > 0) setLoading(false)
    setLoading(false)
  }, [profiles])

  console.info('ON DUTY > PROFILES: ', profiles)
  // console.info('ON DUTY > PROFILES LENGTH: ', profiles.length)

  console.info(
    jsonDump({
      loading,
      profilesPrepared,
      profilesLength: profiles.length,
    })
  )

  return loading ? (
    <Box className={classes.loading}>
      <CircularProgress />
    </Box>
  ) : (
    <div className={classes.root}>
      <Helmet>
        <title>Tutors On Duty Now</title>
      </Helmet>
      <div className={classes.content}>
        <div className={classes.tutorContainer}>
          <div className={classes.tutorDisplay} onClick={() => console.info(dateTimeDisplay)}>
            {profiles.length === 1 ? (
              <SingleTutor profiles={profiles} />
            ) : profiles.length === 2 ? (
              <DoubleTutor profiles={profiles} />
            ) : profiles.length === 3 ? (
              <TripleTutor profiles={profiles} />
            ) : profiles.length === 4 ? (
              <QuadTutor profiles={profiles} />
            ) : (
              <Welcome error={contentsError} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OnDuty
