import axios from 'axios'
import { UserRow } from 'types/supabase.database.custom.types'

export const fetchUserByFirstLast = async (first: string, last: string): Promise<UserRow[]> => {
  try {
    // console.log('fetchUserByFirstLast: ', { first, last })

    const url = `${process.env.REACT_APP_SCANNER_API}/users/find/${first}/${last}`
    console.warn('fetchUserByFirstLast: ', url)

    const response = await axios.get(url)
    return response.data
  } catch (error) {
    console.trace(error)
    // throw new Error('fetchUserByFirstLast failed.')
    return []
  }
}
