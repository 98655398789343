import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import humanizeDuration from 'humanize-duration'
import { DateTime, Duration } from 'luxon'

import { makeStyles } from '@material-ui/core/styles'

import OnDuty from 'desktop/modes/display/pages/OnDuty'
import { ListSessions } from 'desktop/modes/display/pages/ListSessions'

import { DEBUG_FLAG } from 'constants/app'
import { getQueryParams } from 'utils/paths'

const REFRESH_MS = Number(process.env.REACT_APP_REFRESH_MS) || 1000

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'grid',
      height: '100vh',
      width: '100vw',
      gridTemplate: `
        "header" auto
        "content" 1fr
        "footer" auto
        / auto
      `,
    },

    headerGridPanel: {
      gridArea: 'header',
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '0.8rem',
      color: theme.palette.grey[500],
    },

    contentGridPanel: {
      gridArea: 'content',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      minHeight: '100%',
      minWidth: '100%',
    },

    // Header Grid Panel
    stat: {
      display: 'flex',
      marginRight: theme.spacing(1.0),
    },

    statName: {
      marginRight: theme.spacing(0.5),
    },

    statValue: {
      fontWeight: 'bold',
    },

    tooltip: {
      fontFamily: 'monospace',
      whiteSpace: 'pre',
    },
  }),
  { name: 'Homepage' }
)

const pageMap: {
  [key: string]: React.FC<any>
} = {
  OnDuty,
  ListSessions,
}

const propsMap: {
  [key: string]: any
} = {
  OnDuty: {},
  ListSessions: {},
}

// Display pages in order
const getNextPageKey = (index: number) => {
  const keys = Object.keys(pageMap)
  return keys[index % keys.length]
}

interface HomepageProps {}

const Homepage = (props: HomepageProps) => {
  const classes = useStyles(props)

  const params = new URLSearchParams(location.search)
  const queryParams = getQueryParams(params)

  // Refresh with every load of this component
  const dateTimeDisplay = DateTime.local().toLocaleString(DateTime.DATETIME_HUGE_WITH_SECONDS)

  const [refreshCount, setRefreshCount] = useState(0)
  const [page, setPage] = useState(getNextPageKey(0))
  const [humanUptime, setHumanUptime] = useState<string>('')
  const [debugString, setDebugString] = useState({})

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshCount(refreshes => {
        const newCount = refreshes + 1
        const key = getNextPageKey(newCount)
        setPage(key)
        return newCount
      })
    }, REFRESH_MS)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const seconds = (refreshCount * REFRESH_MS) / 1000
    const duration = Duration.fromObject({ seconds })
    const formatted = duration.valueOf()

    const humanUptime = humanizeDuration(formatted)

    const debugString = JSON.stringify(
      {
        humanUptime,
        page,
        refreshCount,
        REFRESH_MS,
      },
      null,
      2
    )

    setHumanUptime(humanUptime)
    setDebugString(debugString)
  }, [refreshCount])

  const Page = pageMap[page] // dynamically select the Page which will render below
  const pageProps = propsMap[page]

  return (
    <div
      className={classes.root}
      onClick={() => console.info(JSON.stringify(process.env, null, 2))}
    >
      <Helmet>
        <title>Homepage</title>
      </Helmet>
      <div className={classes.headerGridPanel}>
        {queryParams[DEBUG_FLAG] && (
          <>
            <div className={classes.stat}>
              <div className={classes.statValue}>{dateTimeDisplay}</div>
            </div>
            <div className={classes.stat}>
              <div className={classes.statName}>MS per Refresh:</div>
              <div className={classes.statValue}>{REFRESH_MS}</div>
            </div>
            <div className={classes.stat}>
              <div className={classes.statName}>Refreshes:</div>
              <div className={classes.statValue}>{refreshCount}</div>
            </div>
            <div className={classes.stat}>
              <div className={classes.statName}>Uptime:</div>
              <div className={classes.statValue}>{humanUptime}</div>
            </div>
            <div className={classes.stat}>
              <div className={classes.statName}>Page:</div>
              <div className={classes.statValue}>{page}</div>
            </div>
          </>
        )}
      </div>
      <div className={classes.contentGridPanel}>
        <Page {...pageProps} debugString={debugString} />
      </div>
    </div>
  )
}

export default Homepage
