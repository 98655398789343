// ICS URL feeds from Google Calendar
export const TUTORING_1010_URL =
  'https://calendar.google.com/calendar/ical/6to0vu3lbn1e3jlfc8acdp5l3s%40group.calendar.google.com/public/basic.ics'

export const TUTORING_2120_URL =
  'https://calendar.google.com/calendar/ical/3nkjr96r067i8o48qu7jk70li4%40group.calendar.google.com/public/basic.ics'

export const TUTORING_GENERAL_URL =
  'https://calendar.google.com/calendar/ical/72g4963o60tgncaps39m7i4a1g%40group.calendar.google.com/public/basic.ics'

export const EVENTS_CALENDAR_URL =
  'https://calendar.google.com/calendar/ical/0f40ggpifavdp2gqfccqokl04k%40group.calendar.google.com/public/basic.ics'

// export const CORS_PROXY_URL = 'https://cors-proxy-server-six.vercel.app/raw/'
// export const CORS_PROXY_URL = 'http://dev2.lmucs.io:4000/raw/'
export const CORS_PROXY_URL = 'https://cors.lmucs.io/raw/'

export const RAW_FEED_LIST = [
  TUTORING_1010_URL,
  TUTORING_2120_URL,
  TUTORING_GENERAL_URL,
  EVENTS_CALENDAR_URL,
]
