import { Student } from 'lmucs-shared-types'

export const sortRosterStudents = (students: Student[]): Student[] => {
  return [...students].sort((a, b) => {
    // Sort by lead status (lead students first)
    // if (a.lead && !b.lead) return -1
    // if (!a.lead && b.lead) return 1

    // Sort by startDate ascending
    // if (a.startDate > b.startDate) return 1
    // if (a.startDate < b.startDate) return -1

    // Sort by recitations = true first
    if (a.recitations && !b.recitations) return -1
    if (!a.recitations && b.recitations) return 1

    // Sort by classOf ascending
    if (a.classOf > b.classOf) return 1
    if (a.classOf < b.classOf) return -1

    // Sort by firstName, then by lastName
    return a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName)
  })
}
