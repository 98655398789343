/**
 * Determine if URL is valid
 * https://stackoverflow.com/a/43467144/10415969
 * @param string - URL to validate
 * @returns boolean
 */
export const isValidHttpUrl = (string: string) => {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const checkUrl = async (url: string) => {
  if (!isValidHttpUrl(url)) {
    return false
  }

  try {
    const response = await fetch(url, {
      method: 'HEAD', // Use 'HEAD' to get headers without downloading the whole body
    })
    return response.status === 200
  } catch (error) {
    console.error('Error fetching the URL:', error)
    return false
  }
}
