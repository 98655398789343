import {
  CANCELLED,
  DELAYED,
  IN_PROGRESS,
  ON_DUTY,
  ON_TIME,
  ShiftStatus,
} from 'types/supabase.database.custom.types'

export const getTextShadowStringByStatus = (
  status: ShiftStatus,
  options?: {
    profile: boolean
  }
): string => {
  let strokeColor = 'transparent'
  const strokeWidth = 3
  switch (status) {
    case ON_DUTY:
      strokeColor = 'white'
      break
    case DELAYED:
    case CANCELLED:
      strokeColor = 'white'
  }

  if (options?.profile) {
    switch (status) {
      case ON_DUTY:
      case DELAYED:
      case CANCELLED:
        strokeColor = 'white'
    }
  }

  // Stroke all eight ways around the text (commas are important for syntax)
  // Starts at top left and goes counter-clockwise (no inline comments allowed here)
  return `
    -${strokeWidth}px -${strokeWidth}px 0 ${strokeColor}, 
    -${strokeWidth}px               0px 0 ${strokeColor}, 
    -${strokeWidth}px  ${strokeWidth}px 0 ${strokeColor}, 
                  0px  ${strokeWidth}px 0 ${strokeColor}, 
     ${strokeWidth}px  ${strokeWidth}px 0 ${strokeColor}, 
     ${strokeWidth}px               0px 0 ${strokeColor}, 
     ${strokeWidth}px -${strokeWidth}px 0 ${strokeColor}, 
                  0px -${strokeWidth}px 0 ${strokeColor}  
  `
}

export const getShiftStatusColor = (
  status: ShiftStatus,
  options?: {
    profile: boolean
  }
): string => {
  let shiftStatusColor = 'black'
  switch (status) {
    case ON_DUTY:
      shiftStatusColor = 'black'
      break
    case IN_PROGRESS:
      shiftStatusColor = 'gold'
      break
    case CANCELLED:
    case DELAYED:
      shiftStatusColor = 'red'
      break
  }

  if (options?.profile) {
    switch (status) {
      case ON_DUTY:
      case ON_TIME:
        shiftStatusColor = 'limegreen'
        break
    }
  }

  return shiftStatusColor
}
