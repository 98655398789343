/**
 * Global debugging tools like console.info() of environment variables
 */
import { Outlet } from 'react-router-dom'

export const DebugContainer = () => {
  const {
    REACT_APP_PUBLIC_API,
    REACT_APP_SCANNER_API,
    REACT_APP_SUPABASE_PROJECT_URL,
    REACT_APP_REFRESH_MS,
  } = process.env

  const envVars = {
    REACT_APP_PUBLIC_API,
    REACT_APP_SCANNER_API,
    REACT_APP_SUPABASE_PROJECT_URL,
    REACT_APP_REFRESH_MS,
  }

  return (
    <div onClick={() => console.info(JSON.stringify(envVars, null, 2))}>
      <Outlet />
    </div>
  )
}
