// App-specific functions
export const getModeFromPath = () => {
  const pathname = window.location.pathname
  const parts = pathname.split('/')
  const mode = parts[1]
  return mode
}

export const getLastTokenOfPath = () => {
  const url = window.location.pathname
  const tokens = url.split('/').filter(Boolean)
  return tokens.length ? tokens[tokens.length - 1] : null
}

export const getQueryParams = (searchParams: URLSearchParams) => {
  const queryParams: Record<string, string> = {}

  const entriesArray = [...searchParams.entries()]
  for (const [key, value] of entriesArray) {
    queryParams[key] = value
  }
  return queryParams
}
