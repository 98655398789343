import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { TutorDisplayProps } from 'types/displayTypes'
import { getStudentPhotoPath } from 'utils/profiles'

import { ProfileCard } from './ProfileCard'

// Refactor styles and copied code from Single and DoubleTutor
const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
    },
  }),
  { name: 'QuadTutor' }
)

export const QuadTutor = (props: TutorDisplayProps) => {
  const classes = useStyles(props)
  const { profiles } = props

  return (
    <div className={classes.root}>
      {profiles?.map((profile, index) => {
        return <ProfileCard key={index} profile={profile} size="small" />
      })}
    </div>
  )
}
