/**
 * Desktop Container test (copy of MobileContainer)
 */
import { Outlet } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'grid',
      height: '100vh',
      width: '100vw',
      gridTemplate: `
        "content" auto
        / auto
      `,
    },

    // Grid Panels
    contentGridPanel: {
      gridArea: 'content',
      display: 'flex', // grid exterior, flex interior
      flex: 1,
      flexDirection: 'column',

      alignItems: 'center',

      minHeight: '100%',
      minWidth: '100%',
    },
  }),
  { name: 'DesktopGridContainerMovie' }
)

interface DesktopGridContainerMovieProps {}

export const DesktopGridContainerMovie = (props: DesktopGridContainerMovieProps) => {
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <div style={{ position: 'relative', overflow: 'hidden', height: '100vh' }}>
        <video
          autoPlay
          muted
          loop
          style={{
            position: 'absolute',
            width: '100%',
            left: '50%',
            top: '50%',
            height: '100%',
            objectFit: 'cover',
            transform: 'translate(-50%, -50%)',
            zIndex: -1,
          }}
        >
          {/* <source src={process.env.PUBLIC_URL + '/mp4/fire.mp4'} type="video/mp4" /> */}
          {/* <source src={process.env.PUBLIC_URL + '/asc/fish1080.asc'} type="video/mp4" /> */}
          {/* <source src={process.env.PUBLIC_URL + '/mp4/bluefish720.mp4'} type="video/mp4" /> */}
          <source src={process.env.PUBLIC_URL + '/mp4/space720.mp4'} type="video/mp4" />
        </video>
        <div className={classes.contentGridPanel}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
