import { DateTime } from 'luxon'

import { LOCAL_24HOUR_FORMAT } from 'constants/formatting'

import { Event } from '@masaok/scanner-shared-next-public/types/event.types'

import {
  COMPLETE,
  ON_DUTY,
  ON_TIME,
  SCHEDULED,
  ShiftStatus,
} from 'types/supabase.database.custom.types'

export const getShiftStatus = (
  inputDateTimeUtcIso: string,
  event: Event,
  shiftStatus: ShiftStatus
) => {
  const { summary: eventSummary } = event
  const now24 = DateTime.fromISO(inputDateTimeUtcIso).toFormat(LOCAL_24HOUR_FORMAT)
  const start24 = DateTime.fromISO(event.startISO).toFormat(LOCAL_24HOUR_FORMAT)
  const end24 = DateTime.fromISO(event.endISO).toFormat(LOCAL_24HOUR_FORMAT)

  console.info('getShiftStatus > 24 local: ', { summary: event.summary, now24, start24, end24 })

  // let eventShiftStatus: ShiftStatus = shift?.shift_status as ShiftStatus
  let eventShiftStatus: ShiftStatus = shiftStatus

  console.info('getShiftStatus > initial status: ', {
    eventSummary,
    eventShiftStatus,
  })

  // If it's in the past and there was no special status, then it's COMPLETE
  if (end24 <= now24) {
    console.info(`getShiftStatus > it's in the past > BEFORE: `, {
      end24,
      now24,
      eventSummary,
      eventShiftStatus,
    })

    eventShiftStatus =
      shiftStatus !== undefined &&
      shiftStatus !== '' &&
      shiftStatus !== ON_TIME &&
      shiftStatus !== ON_DUTY
        ? shiftStatus
        : COMPLETE

    console.info(`getShiftStatus > it's in the past > AFTER: `, {
      end24,
      now24,
      eventSummary,
      eventShiftStatus,
    })
  }

  console.info('getShiftStatus > is it complete?: ', {
    eventSummary,
    eventShiftStatus,
  })

  // If it's currently happening, and no status is specified, then we're ON DUTY
  if (start24 <= now24 && now24 < end24) {
    if (!eventShiftStatus || eventShiftStatus === ON_TIME) eventShiftStatus = ON_DUTY
  }

  console.info('getShiftStatus > is it currently happening?: ', {
    eventSummary,
    eventShiftStatus,
  })

  // Transition: if the shift is ON TIME, change it to SCHEDULED
  if (eventShiftStatus === ON_TIME) eventShiftStatus = SCHEDULED

  // console.info('getShiftStatus > is it scheduled?: ', eventShiftStatus)

  // Otherwise, if it's still not specified, then it's in the future and defaulted to ON TIME
  if (!eventShiftStatus) eventShiftStatus = SCHEDULED

  return eventShiftStatus
}
