import { Tables } from './supabase.database.types'

// Basic tables
export const ACTIONS_TABLE = 'actions'
export const APPEALS_TABLE = 'appeals'
export const ATTENDANCE_TABLE = 'attendance'
export const SCANS_TABLE = 'scans'
export const USERS_TABLE = 'users'
export const SHIFTS_TABLE = 'shifts'

// Basic enum types
export const CANCELLED = 'CANCELLED'
export const COMPLETE = 'COMPLETE'
export const DEFAULT_SHIFT_STATUS = ''
export const DELAYED = 'DELAYED'
export const FORGOT = 'FORGOT'
export const IN_PROGRESS = 'IN PROGRESS'
export const ON_DUTY = 'ON DUTY'
export const ON_TIME = 'ON TIME'
export const SCHEDULED = 'SCHEDULED'

export type ShiftStatus =
  | typeof CANCELLED
  | typeof COMPLETE
  | typeof DELAYED
  | typeof DEFAULT_SHIFT_STATUS
  | typeof FORGOT
  | typeof IN_PROGRESS
  | typeof ON_TIME
  | typeof ON_DUTY
  | typeof SCHEDULED

// Basic tables
export type ActionRow = Partial<Tables<'actions'>>
export type AttendanceRow = Partial<Tables<'attendance'>>
export type ScanRow = Partial<Tables<'scans'>>
export type ShiftRow = Partial<Tables<'shifts'>>
export type UserRow = Partial<Tables<'users'>>

// Any table row from above
export type TableRow = AttendanceRow | ScanRow | UserRow

// Admin Attendance Display Table
export interface AttendanceDisplayPlainRow extends AttendanceRow {
  scans?: ScanRow
}

// Flattened Admin Attendance Row
export interface AttendanceDisplayRow extends AttendanceDisplayPlainRow {
  scan_id?: number
  scan_email?: string
  scan_timestamp?: string
}
