import axios from 'axios'
import { useQuery } from 'react-query'

import { PUBLIC_API_BASE } from 'constants/app'

import { Student } from 'lmucs-shared-types'

// Function to fetch students data
const fetchSignUps = async (): Promise<Student[]> => {
  const url = `${PUBLIC_API_BASE}/signups`

  console.warn(url)

  let response
  try {
    response = await axios.get(url)
  } catch (error) {
    console.trace(error)
    throw new Error('Network response was not ok')
  }

  // Validate data
  const data = response.data
  const list = data['data']
  if (!Array.isArray(list)) {
    throw new Error('Data is not an array')
  }

  return list as Student[]
}

// Custom hook using react-query
export const useSignUps = () => {
  return useQuery<Student[], Error>('signups', fetchSignUps)
}
