import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    type: 'light',

    // Override main colors here (Buttons, etc.)
    primary: {
      light: '#4CAF50', // light green
      main: '#2E7D32', // green (default MUI theme "success" color)
      dark: '#1B5E20', // dark green
      gradient:
        'linear-gradient(45deg, rgba(19,75,23,1) 0%, rgba(46,125,50,1) 16%, rgba(102,240,107,1) 100%)',
    },

    // secondary: {
    //   main: '#55B89F', // green turqoise
    //   light: '#E8CC8D', // beige
    // },
  },

  typography: {
    // Set global fonts here
    // https://material-ui.com/customization/typography/#font-family

    // Default font
    fontFamily: ['Open Sans', 'sans-serif'].join(','),

    // Title fonts
    h1: {
      fontSize: '2rem',
    },

    h2: {
      fontSize: '1.75rem',
    },

    h3: {
      fontSize: '1.5rem',
    },

    h4: {
      fontSize: '1.25rem',
    },

    h5: {
      fontSize: '1rem',
    },
  },
})

export default theme
