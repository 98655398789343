import { ProfileSize } from 'styles/profile.styles'

export type ProfileIntervalDelimiterPropTypes = {
  size?: ProfileSize
}

export const ProfileIntervalDelimiter = (props: ProfileIntervalDelimiterPropTypes) => {
  const { size } = props

  switch (size) {
    default:
      return <>&nbsp;-&nbsp;</>
  }
}
