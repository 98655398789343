// Function to extract the first name and last initial without the period
export const extractNameParts = (
  summary: string
): { firstName: string; lastInitial: string } | null => {
  // Use a regular expression to match the name pattern
  const namePattern = /([A-Za-z]+)\s([A-Za-z]+)\.?\s+\(/
  const match = summary.match(namePattern)

  // console.log('extractNameParts > match: ', match)

  if (match) {
    // Extract the first name and last initial (without the period)
    const firstName = match[1]
    const lastInitial = match[2]

    if (firstName && lastInitial) {
      // console.log('extractNameParts > firstName/lastInitial: ', firstName, lastInitial)
      return { firstName, lastInitial }
    } else {
      return null
    }
  } else {
    // return { firstName: 'Name', lastInitial: 'Not Found' }
    return null
  }
}
