import { createTheme } from '@material-ui/core/styles'

const LIGHT_BLUE = '#42a5f5'
const SLIGHTLY_LIGHT_BLUE = '#2686e7'

const theme = createTheme({
  palette: {
    type: 'light',

    // Override main colors here (Buttons, etc.)
    primary: {
      light: LIGHT_BLUE,
      main: '#1976d2', // blue default
      dark: '#1565c0', // dark blue
      // gradient:
      //   'linear-gradient(32deg, rgba(21,101,192,1) 0%, rgba(25,118,210,1) 32%, rgba(102,184,251,1) 100%)',
    },

    gradientBackground:
      'linear-gradient(32deg, rgba(21,101,192,1) 0%, rgba(25,118,210,1) 32%, rgba(102,184,251,1) 100%)',

    secondary: {
      main: '#55B89F', // green turqoise
      light: '#E8CC8D', // beige
    },

    navBar: {
      light: SLIGHTLY_LIGHT_BLUE,
    },

    dialog: {
      background: '#EEE',
      tabs: {
        background: '#DDD',
      },
      footer: {
        background: '#EEE',
      },
    },
  },

  typography: {
    // Set global fonts here
    // https://material-ui.com/customization/typography/#font-family

    // Default font
    fontFamily: ['Open Sans', 'sans-serif'].join(','),

    // Body fonts
    body1: {
      fontSize: '1.5rem',
    },

    // Title fonts
    h1: {
      fontSize: '3rem',
      fontWeight: 'bold',
    },

    h2: {
      fontSize: '2.75rem',
    },

    h3: {
      fontSize: '2.5rem',
    },

    h4: {
      fontSize: '2.25rem',
    },

    h5: {
      fontSize: '2rem',
    },
  },
})

export default theme
