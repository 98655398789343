/**
 * MUI v5
 */
import { Theme } from '@mui/material'
import { DISPLAY_MEDIUM, DISPLAY_SMALL } from 'constants/formatting'

export type ProfileSize = 'small' | 'medium' | 'large'

export const getNameStyles = (theme: Theme) =>
  ({
    fontWeight: 'bold',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    margin: theme.spacing(0),
  } as const)

export const getProfileIntervalStyles = (theme: Theme) =>
  ({
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    margin: theme.spacing(0),
  } as const)

export const getProfileContainerStyles = (theme: Theme) =>
  ({
    borderRadius: theme.spacing(3),
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Movie theme
  } as const)

export const getProfileImageContainerStyle = (size: ProfileSize = DISPLAY_MEDIUM) => {
  switch (size) {
    case DISPLAY_SMALL:
      return {
        height: '42vh',
      }
    default:
      return {
        height: '50vh',
      }
  }
}

export const getProfileNameStyle = (size: ProfileSize = DISPLAY_MEDIUM) => {
  switch (size) {
    case DISPLAY_SMALL:
      return {
        fontSize: '3.0vw',
        lineHeight: '4.5vw',
      }
    default:
      return {
        fontSize: '4.5vw',
        lineHeight: '5.9vw',
      }
  }
}

export const getProfileTimeIntervalStyle = (size: ProfileSize = DISPLAY_MEDIUM) => {
  switch (size) {
    case DISPLAY_SMALL:
      return {
        fontSize: '2.0vw',
      }
    default:
      return {
        fontSize: '3.0vw',
      }
  }
}

export const getProfileStatusStyle = (size: ProfileSize = DISPLAY_MEDIUM) => {
  switch (size) {
    case DISPLAY_SMALL:
      return {
        fontSize: '3.0vw',
      }
    default:
      return {
        fontSize: '3.0vw',
      }
  }
}
