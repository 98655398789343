import { useEffect, useState } from 'react'
import { SHIFTS_TABLE, ShiftRow } from 'types/supabase.database.custom.types'
import { supabase } from 'clients/supabaseClient'
import { RealtimePostgresUpdatePayload } from '@supabase/supabase-js'

export interface UseShiftsReturn {
  data: ShiftRow[] | undefined
  isLoading: boolean
  error: unknown
}

export const useShiftsRealTimeUpdates = (): ShiftRow => {
  const [newData, setNewData] = useState<ShiftRow>({})

  useEffect(() => {
    const handleUpdates = (payload: RealtimePostgresUpdatePayload<ShiftRow>) => {
      // console.log('PAYLOAD: ', payload)
      setNewData(payload.new)
    }

    try {
      const subscription = supabase
        .channel(SHIFTS_TABLE)
        .on(
          'postgres_changes',
          { event: 'UPDATE', schema: 'public', table: SHIFTS_TABLE },
          handleUpdates
        )
        .subscribe()

      // Cleanup function to unsubscribe
      return () => {
        subscription.unsubscribe()
      }
    } catch (error) {
      console.error('Error subscribing to updates: ', error)
    }
  }, [newData])

  return newData
}
