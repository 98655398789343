import { Helmet } from 'react-helmet-async'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      minHeight: '100%',
      minWidth: '100%',
      backgroundColor: 'blue',
    },

    content: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '3rem',
      fontWeight: 'bold',
    },
  }),
  { name: 'PageBlue' }
)

const PageBlue = props => {
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <Helmet>
        <title>PageBlue</title>
      </Helmet>
      <div className={classes.content}>PAGE BLUE</div>
    </div>
  )
}

export default PageBlue
