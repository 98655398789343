import { useEffect, useState } from 'react'

import { DateTime } from 'luxon'
import { Helmet } from 'react-helmet-async'

import { makeStyles } from '@material-ui/core/styles'

import { Student } from 'lmucs-shared-types'
import { getQueryParams } from 'utils/paths'
import {
  checkAllImageUrls,
  getDefaultPlaceholderPhotoPath,
  getStudentPhotoPath,
} from 'utils/profiles'
import { sortRosterStudents } from 'utils/roster'
import { useSignUps } from 'hooks/useSignUps'
import { ImageUrlCheck } from 'types/images.types'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      background: theme.palette.gradientBackground,
      padding: theme.spacing(2),
      alignItems: 'center',
    },

    title: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      marginBottom: theme.spacing(0.3),
    },

    roster: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },

    rosterProfile: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: theme.spacing(0.3),
    },

    name: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },

    imageContainer: {
      height: '20vh', // must be height number, or placeholder will be too wide
    },

    photo: {
      // ...getProfileContainerStyles(theme),
      height: '100%', // must be height 100%
    },
  }),
  { name: 'PhotoRoster' }
)

export const PhotoRoster = () => {
  const classes = useStyles()

  const params = new URLSearchParams(location.search)
  const queryParams = getQueryParams(params)

  const { data: signups, isLoading: signupsLoading } = useSignUps()

  const [images, setImages] = useState<string[]>([])
  const [tutors, setTutors] = useState<Student[]>([])

  const [imageUrlChecks, setImageUrlChecks] = useState<ImageUrlCheck[]>([])

  // Sort the tutors first
  useEffect(() => {
    const prepareTutors = async () => {
      console.log('BEFORE FILTERING: ', signups)
      // Filter only signups without a role
      let filteredSignups = signups?.filter(signup => !signup.role)

      console.log('AFTER FILTERING BY ROLE: ', filteredSignups)

      // Filter only signups with primary assigned course
      filteredSignups = filteredSignups?.filter(signup => signup.primary)

      console.log('AFTER ALL FILTERING: ', filteredSignups)

      console.log('BEFORE SORTING: ', filteredSignups)
      const sortedTutors = sortRosterStudents(filteredSignups || [])
      console.log('AFTER SORTING: ', sortedTutors)
      const imageUrlChecks = await checkAllImageUrls(sortedTutors)

      console.log('imageUrlChecks: ', imageUrlChecks)
      setTutors(sortedTutors)
      setImageUrlChecks(imageUrlChecks)
    }
    prepareTutors()
  }, [signupsLoading])

  // Dynamically import the image
  useEffect(() => {
    const importImages = async () => {
      const newImages: string[] = []
      let index = 0
      for (const tutor of tutors || []) {
        let imageURL = await getStudentPhotoPath(tutor)

        // TODO: Use the list of imageUrlChecks to determine if the image is valid
        // TODO: If not, replace the imageURL with a placeholder URL (see old code in profiles.ts)

        const imageIsValid = imageUrlChecks[index].isValid
        if (!imageIsValid) {
          console.warn('Invalid image URL: ', { imageURL, imageIsValid })
          imageURL = getDefaultPlaceholderPhotoPath()
          console.warn('Replaced with placeholder: ', imageURL)
        }

        newImages.push(imageURL)
        index += 1
      }
      setImages(newImages) // call async setter only once
    }
    importImages()
  }, [tutors])

  console.log('signups: ', signups)
  console.log('tutors: ', tutors)

  return (
    <div className={classes.root}>
      <Helmet>
        <title>TA Photo Roster {DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss')}</title>
      </Helmet>
      <div className={classes.title}>Computer Science Tutors</div>
      <div className={classes.roster}>
        {signupsLoading ? (
          <>loading</>
        ) : (
          tutors.map((tutor, index) => {
            const image = images[index]

            return (
              <div
                key={index}
                onClick={() => console.log(JSON.stringify({ index, tutor, image }, null, 2))}
              >
                <div className={classes.rosterProfile}>
                  <div className={classes.imageContainer}>
                    {image && <img className={classes.photo} src={image} alt={tutor.firstName} />}
                  </div>
                  <div className={classes.name}>{tutor.nickname || tutor.firstName}</div>
                </div>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}
