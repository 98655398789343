import { PUBLIC_API_BASE } from 'constants/app'
import { DEFAULT_PLACEHOLDER_GENDER } from 'constants/profiles'
import { DateTime } from 'luxon'
import { checkUrl } from './urls'

import { FALL, SPRING } from 'types/calendar.types'

import { Student } from 'lmucs-shared-types'

export const getClassLevel = (year: number): string => {
  const currentDate = DateTime.now() // Get the current date using luxon
  const currentYear = currentDate.year // Extract the year
  const currentSemester = currentDate.month >= 8 ? FALL : SPRING
  return getClassLevelFromCurrentYear(year, currentYear, currentSemester)
}

// Separate function to get the class level (without using Luxon, for simpler unit testing)
export const getClassLevelFromCurrentYear = (
  year: number,
  currentYear: number,
  semester: string
): string => {
  switch (semester) {
    case FALL:
      switch (year - currentYear) {
        case 1:
          return 'senior'
        case 2:
          return 'junior'
        case 3:
          return 'sophomore'
        case 4:
          return 'first year'
        default:
          return 'grad'
      }

    default:
      switch (year - currentYear) {
        case 0:
          return 'senior'
        case 1:
          return 'junior'
        case 2:
          return 'sophomore'
        case 3:
          return 'first year'
        default:
          return 'grad'
      }
  }
}

export const getClassLevelDisplayString = (tutor: Student): string => {
  return `🎉&nbsp; ${getClassLevel(tutor.classOf)} &nbsp;🎉`
}

export const getProfilePhotoPath = (tutor: Student): string => {
  const { firstName, lastName, classOf, gradStudent } = tutor

  const firstNameCleaned = firstName.replace(/[^a-zA-Z0-9]/g, '_')
  const lastNameCleaned = lastName.replace(/[^a-zA-Z0-9]/g, '_')

  const gradYear = gradStudent ? 'grad' : classOf

  return `images/students/${gradYear}/${firstNameCleaned.toLowerCase()}_${lastNameCleaned.toLowerCase()}.jpg`
}

export const getDefaultPlaceholderPhotoPath = () => {
  return `${PUBLIC_API_BASE}/images/placeholders/placeholder.jpg`
}

export const getPlaceholderPhotoPath = (tutor: Student) => {
  const gender = tutor.gender || DEFAULT_PLACEHOLDER_GENDER
  return `images/placeholders/${gender}_placeholder.jpg`
}

export const getStudentPhotoPath = async (student: Student): Promise<string> => {
  const profilePhotoPath = getProfilePhotoPath(student)
  const imageURL = `${PUBLIC_API_BASE}/${profilePhotoPath}`
  return imageURL
}

/**
 * Check all image URLs for a list of Students in parallel
 * @param students - Array of Students
 * @returns boolean[] - Array of booleans indicating if the image URL is valid
 */
export async function checkAllImageUrls(
  students: Student[]
): Promise<{ imageUrl: string; isValid: boolean }[]> {
  const checks = students.map(async student => {
    const profilePhotoPath = getProfilePhotoPath(student)
    const imageUrl = `${PUBLIC_API_BASE}/${profilePhotoPath}`
    const isValid = await checkUrl(imageUrl)
    return {
      imageUrl,
      isValid,
    }
  })

  return Promise.all(checks)
}
