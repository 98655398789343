import { Helmet } from 'react-helmet-async'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      background: theme.palette.gradientBackground,
      padding: theme.spacing(2),
      alignItems: 'center',
    },
  }),
  { name: 'Environment' }
)

export const Environment = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Environment</title>
      </Helmet>
      <pre>{JSON.stringify(process.env, null, 2)}</pre>
    </div>
  )
}
