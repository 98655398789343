/**
 * Get raw feeds from a list of API URLs
 */
import { useState, useEffect } from 'react'
import { useQueries } from 'react-query'

import axios from 'axios'
import { getEventSource } from 'utils/calendar'

const useRawFeeds = (feeds: string[]) => {
  const [queryOptions, setQueryOptions] = useState<object[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  const contents: any[] = useQueries(queryOptions)

  // Given input feeds, create a list of query options
  useEffect(() => {
    const options = feeds.map((feed, index) => {
      const corsFeedUrl = getEventSource(feed)
      return {
        queryKey: ['rawFeed', index],
        queryFn: async () => {
          try {
            const res = await axios.get(corsFeedUrl, { transformResponse: r => r })
            return res.data
          } catch (error: any) {
            console.error(error)
            setError(`Feed fetch: ${error.message}`)
          }
        },
      }
    })
    setQueryOptions(options)
  }, [feeds])

  // Set loading state depending on if any of the queries are still loading
  useEffect(() => {
    const isLoading = contents.some(content => content.isLoading)
    const isError = contents.some(content => content.isError)
    setLoading(isLoading)
    setError(isError ? 'Error fetching feed' : '')
  }, [contents])

  return { contents, loading, error }
}

export default useRawFeeds
