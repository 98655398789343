import {
  LARGE_FONT_SIZE,
  MAX_EVENTS_LARGE_TEXT,
  MAX_EVENTS_NORMAL_TEXT,
  NORMAL_FONT_SIZE,
  SMALL_FONT_SIZE,
} from 'constants/listing'
import { Event } from '@masaok/scanner-shared-next-public/types/event.types'

export const getFontSize = (numEvents: number) => {
  let fontSize = ''
  if (numEvents <= MAX_EVENTS_LARGE_TEXT) fontSize = LARGE_FONT_SIZE
  else if (numEvents <= MAX_EVENTS_NORMAL_TEXT) fontSize = NORMAL_FONT_SIZE
  else fontSize = SMALL_FONT_SIZE
  return fontSize
}

/** Returns whether this event is special (non-tutoring)  */
export const isSpecialEvent = (event: Event): boolean => {
  const buildings = 'seaver|doolan|pereira|LSB'
  const events = 'ACM|meeting|recitation|review|session|class|break|hack'
  const regexString = `\\b(${events}|${buildings})`
  const regex = new RegExp(regexString, 'i')
  return regex.test(event.summary)
}
